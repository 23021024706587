import React, { FC, useMemo } from "react";
import { Breadcrumb as BreadcrumbAntd } from "antd";
import classNames from "classnames";
import { Link } from "react-router-dom";

export interface BreadcrumbItem {
  name: string;
  path?: string;
}

export interface BreadcrumbProps {
  className?: string;
  items: BreadcrumbItem[];
}

export const Breadcrumb: FC<BreadcrumbProps> = ({ className, items }) => {
  const extraBreadcrumbItems = useMemo(
    () =>
      items.map(({ name, path }) => (
        <BreadcrumbAntd.Item key={name}>
          {path ? <Link to={path}>{name}</Link> : name}
        </BreadcrumbAntd.Item>
      )),
    [items]
  );

  const breadcrumbItems = useMemo(
    () =>
      [
        <BreadcrumbAntd.Item key="home">
          <Link to="/">Página Inicial</Link>
        </BreadcrumbAntd.Item>,
      ].concat(extraBreadcrumbItems ?? []),
    [extraBreadcrumbItems]
  );

  const breadcrumbsClassName = useMemo(
    () =>
      classNames({
        "xvia-breadcrumb": true,
        [className as string]: !!className,
      }),
    [className]
  );

  return (
    <BreadcrumbAntd className={breadcrumbsClassName}>
      {breadcrumbItems}
    </BreadcrumbAntd>
  );
};
